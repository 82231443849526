import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import ReviewSection from "../components/review-section";
import SeoHero from "../components/seo-hero";
import SeoMapSection from "../components/seo-map-section";
import SeoPageLogos from "../components/seo-page-logos";
import SeoWhatWeSolve from "../components/seo-what-we-solve";
import SeoWwaSection from "../components/seo-wwa-section";

const WebDesignEastbournePage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
        altText
        mediaDetails {
          height
          width
        }
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      jasonImg: wpMediaItem(title: { eq: "Jasoncorneslogo" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      seoImg: wpMediaItem(title: { eq: "SEARCH ENGINE-OPTIMISATION-IMAGE-2" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      seymourImg: wpMediaItem(title: { eq: "Seymourslogo" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      videos: allWpVideo(
        filter: { videoFields: { seoPage: { in: "Web Design Eastbourne" } } }
        sort: { fields: dateGmt, order: DESC }
      ) {
        nodes {
          videoFields {
            url
            seoPage
          }
          title
        }
      }
      sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }

      foehImg: wpMediaItem(title: { eq: "capitaltocoast-fullpage" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }

      ninjaImg: wpMediaItem(title: { eq: "capitaltocoast-fullpage" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }

      wpSeoPage(title: { eq: "Web Design Eastbourne" }) {
        contentForHeadTag {
          content
        }
        seo {
          metaDesc
          title
          twitterImage {
            altText
            mediaDetails {
              height
              width
            }
            link
            gatsbyImage(
              width: 1920
              quality: 75
              formats: [WEBP]
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
          opengraphTitle
          opengraphDescription
        }
      }
      icon: wpMediaItem(title: { eq: "icon" }) {
        link
      }
      allWpPost(
        filter: { blogFields: { seoPage: { in: "Web Design Eastbourne" } } }
        sort: { fields: dateGmt, order: DESC }
      ) {
        nodes {
          slug
          excerpt
          title
          dateGmt
          blogFields {
            category
            keywords
            seoTitle
            featuredImage {
              gatsbyImage(
                width: 1920
                quality: 75
                formats: [WEBP]
                layout: CONSTRAINED
                placeholder: BLURRED
              )
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const schema = data.wpSeoPage.contentForHeadTag.content;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Web Design Eastbourne",
        item: {
          url: `${siteUrl}/web-design-eastbourne`,
          id: `${siteUrl}/web-design-eastbourne`,
        },
      },
    ],
  };
  
  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">{schema}</script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={data.wpSeoPage.seo.title}
        description={data.wpSeoPage.seo.metaDesc}
        language="en-gb"
        openGraph={{
          type: "website",
          url: `${siteUrl}/web-design-eastbourne`,
          title: `${data.wpSeoPage.seo.opengraphTitle}`,
          description: `${data.wpSeoPage.seo.opengraphDescription}`,
          images: [
            {
              url: `${data.wpSeoPage.seo.twitterImage.link}`,
              width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
              height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
              alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
            },
          ],
        }}
      />

      <Layout>
        <section>
          <SeoHero
            title={`Web Design \n<span class="text-secondary">Eastbourne</span>`}
            description={
              <span>
                Struggling to stand out online? Transform your Eastbourne business with a website that not only looks stunning but also leverages the power of Headless WordPress to deliver unparalleled performance and flexibility.
              </span>
            }
            buttons={[
              <Button
                className="px-4 py-2 bg-white border-white w-100 cta-btn w-md-auto mt-md-4 me-md-3 fs-5 blue-link-yellow"
                variant="primary"
                href="#form"
              >
                Get Your Free Consultation
              </Button>
              
            ]}
          />
        </section>

        <SeoWwaSection
          spanXl={6}
          spanLg={8}
          smallHeading="Web Design Agency in Eastbourne"
          heading="Is Your Eastbourne Business Missing Out on Online Profits?"
          text={
            <div>
              <p>
                Imagine your website as a relentless sales machine, generating leads and closing deals around the clock. At <strong>RJM Digital</strong>, we specialise in crafting bespoke websites using Headless WordPress that don't just dazzle—they deliver measurable results.
              </p>
              <p>
                As a <strong>leading web design agency in Eastbourne</strong>, we prioritise your website as the cornerstone of your digital strategy. Headless WordPress allows us to create lightning-fast, highly secure, and scalable websites that adapt seamlessly to your growing business needs.
              </p>
              <p>
                Our aggressive, results-driven team doesn't wait around. We jump straight into action, implementing cutting-edge technologies and proven strategies that deliver substantial business growth and a tangible return on your investment.
              </p>
            </div>
          }
          buttonText="Talk to an Expert"
          buttonUrl="#form"
          noImages
        />
        <section className="pb-5 pb-lg-7">
          <SeoPageLogos heading={null} page="Web Design Eastbourne" />
        </section>

        <SeoWhatWeSolve
          buttonText="Audit My Website"
          buttonUrl="/web-design-audit-eastbourne"
          heading={<span>Experts in Headless WordPress Web Design for Eastbourne Companies</span>}
          image={data.foehImg.gatsbyImage}
          imageAlt={data.foehImg.altText}
          imgHeight="35rem"
          last
          text={
            <div>
              <h3 className="text-primary fs-2 ">
                Future-Proof <span className="text-light-blue">Website Architecture</span>
              </h3>
              <p>
                Embrace the future with Headless WordPress, offering unparalleled flexibility and control over your website's architecture. This approach ensures your site is easily maintainable, scalable, and ready to integrate with the latest technologies, keeping you ahead of the competition.
              </p>
              <h3 className="mt-5 text-primary fs-2">
                Lightning-Fast <span className="text-light-blue">Performance</span>
              </h3>
              <p>
                Speed is crucial in today’s digital landscape. Headless WordPress allows us to optimise your website for lightning-fast loading times, enhancing user experience and boosting your search engine rankings. A faster website means happier visitors and higher conversion rates.
              </p>
              <h3 className="mt-5 text-primary fs-2">
                Enhanced <span className="text-light-blue">Security</span>
              </h3>
              <p>
                Security is non-negotiable. With Headless WordPress, we isolate the front-end from the back-end, minimising vulnerabilities and safeguarding your website against potential threats. Protect your business and your customers with a more secure online presence.
              </p>
            </div>
          }
        />
        <section
          id="results"
          style={{
            background:
              "transparent linear-gradient(52deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
          }}
          className="pb-5 pb-lg-7"
        >
          <Container>
            <Row className="py-5 align-items-center py-xl-0">
              <Col lg={{ span: 8, order: "last" }}>
                <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                  <GatsbyImage
                    className="w-100"
                    image={data.seoImg.gatsbyImage}
                    alt={data.seoImg.altText}
                  />
                </div>
              </Col>
              <Col lg={4} className=" mt-lg-0">
                <h2 className="mb-4 text-white display-5">
                  Unleash Result-Driven Web Design Services
                </h2>
                <p className="text-white">
                  At RJM Digital, we've empowered countless <strong>Eastbourne businesses</strong> to dominate their niches online. Our aggressive web design tactics, powered by Headless WordPress, are designed to amplify your online presence, boost your revenue, and deliver unparalleled web results.
                </p>
                <Button
                  className="px-4 py-2 mt-4 w-100 w-md-auto fs-5 primary-link"
                  variant="white"
                  as={Link}
                  to="#form"
                >
                  Talk to an Expert
                </Button>
                <Button
                  className="px-4 py-2 mt-4 w-100 w-md-auto ms-4 d-none d-xl-inline-block fs-5 primary-link"
                  variant="white"
                  as={Link}
                  to="/contact-us"
                >
                  Get in Touch
                </Button>
              </Col>
            </Row>
            <Row className="py-5 mt-4 align-items-center our-results-webdesign mt-lg-0 py-lg-0 gy-5 gy-lg-0">
              <Col className="p-4 text-center" xs={12} lg={true}>
                <div className="p-4 bg-jason">
                  <GatsbyImage
                    className="mw-100 "
                    image={data.jasonImg.gatsbyImage}
                    alt={data.jasonImg.altText}
                  />
                </div>
              </Col>
              <Col lg={true}>
                <h2 className="text-center text-white ssp-bold text-lg-start fs-3">
                  Our Outstanding Results:
                </h2>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="pb-1 mb-0 ssp-bold text-white display-4">3</p>
                <h3 className="fs-5 text-white">Months to ROI</h3>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="pb-1 mb-0 ssp-bold text-white display-4">200%</p>
                <h3 className="fs-5 text-white">Increase in Traffic</h3>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="pb-1 mb-0 ssp-bold text-white display-4">5x</p>
                <h3 className="fs-5text-white">More Enquiries</h3>
              </Col>
            </Row>
            <Row className="py-5 mt-5 align-items-center our-results-webdesign py-lg-0 gy-5 gy-lg-0">
              <Col xs={12} className="p-4 text-center" lg={true}>
                <div className="p-4 bg-black">
                  <GatsbyImage
                    className="mw-100 "
                    image={data.seymourImg.gatsbyImage}
                    alt={data.seymourImg.altText}
                  />
                </div>
              </Col>
              <Col lg={true}>
                <h2 className="text-center text-white ssp-bold text-lg-start fs-3">
                  Our Unmatched Results:
                </h2>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="pb-1 mb-0 ssp-bold text-white display-4">5</p>
                <h3 className="fs-5 text-white">Location Pages Ranked #1</h3>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="pb-1 mb-0 ssp-bold text-white display-4">50%</p>
                <h3 className="fs-5 text-white">Increase in Conversion Rates</h3>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="pb-1 mb-0 ssp-bold text-white display-4">2x</p>
                <h3 className="fs-5 text-white">More Client Enquiries</h3>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="guarantee" className="py-5 bg-light-grey py-lg-7">
					<Container>
						<Row className="align-items-center justify-content-between gx-6">
						<Col lg={5}>
								<div
									className="mt-5 mt-lg-0"
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100 "
										image={data.ninjaImg.gatsbyImage}
										alt={data.ninjaImg.altText}
									/>
								</div>
							</Col>
							<Col lg={6} className="">
								<h2 className="mb-5 display-5 text-primary">
								Love Your New Website— Or Your Money Back!
								</h2>

								<p>Tired of bland, cookie-cutter websites that barely move the needle?</p>								
								<p>With our “Love It or Your Money Back Guarantee,” you’re not risking a single penny. We deliver a high-performance, conversion-focused website crafted specifically to attract leads, build trust, and boost your bottom line. </p>
								<p>If you’re not over-the-moon, thrilled with our design or you feel it doesn’t set you apart, we’ll hand back every penny you paid. Why settle for mediocrity when you can demand excellence—guaranteed?</p>

								<Button
									className="px-4 py-2 mt-4  w-100 cta-btn w-md-auto fs-5 white-link-yellow"
									variant="primary"
									href="#form"
								>
									Talk to an expert
								</Button>
							</Col>
						
						</Row>
					</Container>
				</section>		

        {/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
          <ReviewSection
            heading="Web Design Eastbourne Reviews"
            page="Our Web Services"
          />
        </section> */}

        {/* <section className="py-5 py-lg-7 ">
          <Container>
            <Row>
              <Col>
                <div className="iframe-container-new">
                  <iframe
                    className="responsive-iframe"
                    src="https://www.youtube.com/embed/1fmeuE1eVUU"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    loading="lazy"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}
        <section className="py-5 py-lg-7 ">
          <Container>
            <Row>
              <Col>
                <h2 className="mb-5 text-primary fs-1">
                  Latest Insights from Our Eastbourne Web Design Experts
                </h2>
              </Col>
            </Row>
            <Row className="g-5 gx-lg-7 h-100">
              {data.allWpPost.nodes.map((blog, index) => (
                <Col lg={6} key={index}>
                  <div
                    className="bg-white position-relative"
                    style={{
                      overflow: "hidden",
                      borderRadius: "20px",
                      boxShadow: "0px 3px 60px #00000029",
                      minHeight: "100%",
                    }}
                  >
                    <GatsbyImage
                      image={blog.blogFields.featuredImage.gatsbyImage}
                      alt={blog.blogFields.featuredImage.altText}
                      className="w-100 seo-blog-image"
                    />
                    <div className="p-4">
                      <h2 className="mb-4 fs-4 pe-lg-10 text-primary ssp-bold">
                        {blog.title}
                      </h2>
                      <div className="w-100" style={{ height: "25px" }}></div>
                      <Link
                        to={`/blog/${convertToSlug(blog.blogFields.category)}/${
                          blog.slug
                        }`}
                        className="bottom-0 pb-4 med-grey-link ssp-bold position-absolute"
                      >
                        READ BLOG &rarr;
                      </Link>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        <section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
          <Container>
            <Row id="form">
              <Col className="text-center">
                <h2 className="mb-3 display-5 text-primary">
                  Talk to an Expert
                </h2>
                <p>
                  Schedule a time that fits your agenda through our online calendar. Let’s discuss how our cutting-edge Headless WordPress web design strategies can propel your Eastbourne business to new heights.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="d-xl-none">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "1150px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
              <Col className="d-none d-xl-block">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "700px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
            </Row>
          </Container>
        </section>
        <SeoMapSection
          heading="Discuss Your Web Design Requirements with Us"
          mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2523.2622758441216!2d0.27970641574303434!3d50.77071147952087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47df735183be68e9%3A0x51b877a5d82a3df!2sRJM%20Digital!5e0!3m2!1sen!2suk!4v1663160300414!5m2!1sen!2suk"
        />
        <OSSection
          link1="/training"
          text1="TRAINING"
          link3="/seo-eastbourne"
          text3="SEO"
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1.altText}
        />
      </Layout>
    </>
  );
};

export default WebDesignEastbournePage;
